import '../util/sentry'

import React from 'react'
import ReactDOM from 'react-dom'
import Totalizer from '../bundles/Totalizer/components/Totalizer'
import HowItWorks from '../bundles/HowItWorks/components/HowItWorks'
import { decode } from 'js-base64'

function totalizer() {
  const node = document.getElementById('totalizer')
  const props = node.dataset.props ? JSON.parse(decode(node.dataset.props)) : {}

  console.log({ props })

  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
      <Totalizer {...props} />,
      node,
    )
  })
}

function howItWorks() {
  const node = document.getElementById('how-it-works')
  const props = node.dataset.props ? JSON.parse(decode(node.dataset.props)) : {}

  console.log({ props })

  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
      <HowItWorks {...props} />,
      node,
    )
  })
}

totalizer()
howItWorks()
