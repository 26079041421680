import React, { useEffect, useState, useRef } from 'react'

const Total = ({ count, length }) => {
  return String(Math.round(count)).padStart(length, '0').split('').map((d, i) => {
    return <div className='digit' key={i}>{d}</div>
  })
}

const easeInOutQuart = t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t
const clamp = (n, min, max) => Math.min(Math.max(n, min), max)

export default () => {
  const [prevTime, setPrevTime] = useState(0)
  const [nextTime, setNextTime] = useState(3000)
  const [prevCount, setPrevCount] = useState(0)
  const [nextCount, setNextCount] = useState(0)
  const [count, setCount] = useState(0)

  const requestRef = useRef(null)
  const prevCountRef = useRef(null)
  const nextCountRef = useRef(null)
  const prevTimeRef = useRef(null)
  const nextTimeRef = useRef(null)
  prevCountRef.current = prevCount
  nextCountRef.current = nextCount
  prevTimeRef.current = prevTime
  nextTimeRef.current = nextTime

  const animate = time => {
    // Change the state according to the animation
    setCount(time)
    const transitionLength = nextTimeRef.current - prevTimeRef.current
    const transitionProgress = easeInOutQuart(clamp((time - prevTimeRef.current) / transitionLength,0.0,1.0))
    const countDiff = nextCountRef.current - prevCountRef.current
    const currentCount = Math.min(prevCountRef.current + transitionProgress * countDiff, nextCountRef.current)
    setCount(currentCount)
    requestRef.current = requestAnimationFrame(animate);
  }
  useEffect(animate, [])

  useEffect(() => {
    App.totalizerSubscription = App.cable.subscriptions.create({
      channel: 'TotalizerChannel'
    }, {
      received: function(data) {
        setPrevCount(nextCountRef.current)
        setNextCount(data.total_clicks)
        setPrevTime(performance.now())
        setNextTime(performance.now() + 3000)
      }
    })
  }, [])

  if (count === 0) return null

  return <div className='totalizer-container'>
    <div className='counter'>
      <Total count={count} length={String(nextCount).length} />
    </div>
    <div className='text lead'>extra website visitors for our customers</div>
  </div>
}