import React, { useState } from 'react'

const Campaigners = <div className="right">
  <div className="small blue">Campaigners</div>
  <h3>More emails, signatures and money — bigger people power</h3>
  <p>
    Every good campaigner knows supporters are your biggest asset, but it’s getting harder to grow power on Facebook. ShareHarder customers see an average 45% increase in petition signatures. So don’t waste precious budget on extra ads. For as little as $30 per month, make change easier.</p>
</div>

const ContentMarketers = <div className="right">
  <div className="small blue">Content Marketers</div>
  <h3>Same content, <strong>much</strong> more traffic</h3>
  <p>
    You’re a professional - you’ve tweaked your blog title, SEO, keywords and landing page. But without optimising share content you’re losing valuable leads. With Facebook getting tougher on content that isn’t engaging, you can’t afford to do that. ShareHarder users typically get 200% more clicks per share, for just a few minutes work.</p>
</div>

const Politicians = <div className="right">
  <div className="small blue">Politicians and parties</div>
  <h3>Your message reaching more voters for greater effect</h3>
  <p>
    Break out of the bubble and cut through. Successful political movements can’t rely just on inauthentic ads. By optimising your organic messaging, you can empower an army of social media activists to engage with their friends. Kickstart your relational organising with ShareHarder. </p>
</div>

export default ({ slide }) => {
  const [item, setItem] = useState(slide || 'campaigners')

  return <div>
    <div className="what-for-you">
      <div className="container">
        <div className="content">
          <h2>What will ShareHarder do for you?</h2>
          <div className="menu">
            <div
              className={`small ${item === 'campaigners' ? 'blue' : 'gray'}`}
              onClick={() => setItem('campaigners')}>Campaigners</div>
            <div
              className={`small ${item === 'contentMarketers' ? 'blue' : 'gray'}`}
              onClick={() => setItem('contentMarketers')}>Content Marketers</div>
            <div
              className={`small ${item === 'politicians' ? 'blue' : 'gray'}`}
              onClick={() => setItem('politicians')}>Politicians and parties</div>
          </div>
        </div>
      </div>
    </div>

    <div className="how-it-works">
      <div className="gradient lighter"></div>
      <div className="content">
        <div className="left">
          <img src="/images/how-it-works.png" />
        </div>
        { item === 'campaigners' && Campaigners }
        { item === 'contentMarketers' && ContentMarketers }
        { item === 'politicians' && Politicians }
      </div>
    </div>
  </div>
}